<template>
  <div>
    <p class="childTitle">Detalhes</p>
    <ReservationCard :obj="obj" :hasBorder="false" />
    <div class="detailsBlock">
      <div class="line"></div>
      <div class="row">
        <p>Informação Geral:</p>
        <p>Valor PVP</p>
        <p>{{ formatPriceNumber(obj.finance.pricePvp, 2, "€") }}</p>
        <p>Valor Sinal de Reserva:</p>
        <p>{{ formatPriceNumber(obj.finance.initialValue, 0, "€") }}</p>
        <p v-if="obj.dealerCode">Concessionário:</p>
        <p v-if="obj.dealerCode">
          {{
            $store.state.allInstallations.find(
              (el) => el.oidParent == obj.dealerCode
            )
              ? $store.state.allInstallations.find(
                  (el) => el.oidParent == obj.dealerCode
                ).designation +
                ", " +
                $store.state.allInstallations.find(
                  (el) => el.oidParent == obj.dealerCode
                ).postalCodeDesig
              : "Error"
          }}
        </p>
      </div>

      <div class="line"></div>
      <div class="row">
        <p>Informação Pessoal</p>
        <p>Nome:</p>
        <p>{{ obj.user.name }}</p>
        <p>Email:</p>
        <p>{{ obj.user.userEmail }}</p>
        <p>Telemóvel:</p>
        <p>{{ obj.user.phoneNumber }}</p>
      </div>
      <div class="row">
        <p>Dados de Faturação</p>
        <p>NIF:</p>
        <p>{{ obj.receipt.nif }}</p>
        <p>Morada, Rua, Travessa:</p>
        <p>{{ obj.receipt.address }}</p>
        <p>Código Postal:</p>
        <p>{{ obj.receipt.zipCode }}</p>
        <p>Localidade:</p>
        <p>{{ obj.receipt.location }}</p>
      </div>
      <div class="row" v-if="obj.reserveType == 0">
        <p>Dados de Referencia de Multibanco</p>
        <p>Entidade:</p>
        <p>{{ obj.entity }}</p>
        <p>Referencia:</p>
        <p>{{ obj.reference }}</p>
        <p>Valor:</p>
        <p>{{ formatPriceNumber(obj.finance.initialValue, 2, "€") }}</p>
        <p>Data limite:</p>
        <p>{{ formatDate(obj.paymentExpiration) }}</p>
      </div>
    </div>
    <ButtonSubmit
      btnClass="primary-btn "
      btnLabel="Fechar"
      @submit="$router.go(-1)"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ReservationCard from "@/components/Header/Children/ReservationCard.vue";
import ButtonSubmit from "@/components/Buttons/ButtonSubmit.vue";
import { formatPriceNumber } from "@/functions/general.js";

export default {
  name: "ReservationDetails",
  components: {
    ReservationCard,
    ButtonSubmit,
  },
  data() {
    return {
      obj: {},
    };
  },
  beforeMount() {
    this.obj = this.getReservationById(this.$route.query.id);
  },
  computed: {
    ...mapGetters({
      getReservationById: "user/getReservationById",
      getUserData: "user/getUserData",
    }),
  },
  methods: {
    formatPriceNumber,
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    formatDate(number) {
      let date = new Date(number);
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("/");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./GeralChildStyle.scss";
</style>
